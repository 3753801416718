@keyframes slideAnimation {
  0% {
    left: 0;
    right: 100%;
  }
  100% {
    left: 0;
    right: 0;
  }
}
