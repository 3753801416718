@import 'stream-chat-react/dist/css/v2/index.css';

.str-chat {
  --str-chat__spacing-0_5: 4px;
  --str-chat__spacing-8: 8px;
  --str-chat__spacing-10: 32px;
  --str-chat__message-bubble-border-radius: 8px;
  --str-chat__message-textarea-border-radius: 22.5px;
  --str-chat__message-textarea-background-color: #fbf9f3; /* shared.white */
  --str-chat__secondary-surface-color: #fbf9f3; /* shared.white */
  --str-chat__background-color: #1974cd; /* pilot.primary400 */
  --str-chat__secondary-background-color: #1974cd; /* pilot.primary400 */
  --str-chat__message-bubble-color: #1a1a1a; /* shared.neutral900 */
  --str-chat__own-message-bubble-color: #e3f7fa; /* pilot.sky100 */
  --str-chat__primary-surface-color: #0b345d; /* pilot.primary600 */
  --str-chat__text-low-emphasis-color: #fbf9f3;
  --str-chat__font-family: Inter, ui-sans-serif;
  --str-chat__message-input-tools-color: #1a1a1a;
  --str-chat__quoted-message-bubble-background-color: #fbf9f3;
  --str-chat__body2-text: 400 0.875rem/130% var(--str-chat__font-family);
  --str-chat__message-input-tools-color: #84edfb;
  --str-chat__message-send-color: #84edfb;
  --str-chat__loading-indicator-color: #0d5194; /* pilot.primary500 */
}

.str-chat__emoji-picker-button svg path {
  fill: #0b345d !important;
}

.str-chat__message--me .str-chat__message-bubble {
  border-block-start: none;
  border-block-end: none;
  border-inline-start: none;
  border-inline-end: none;
}

.str-chat__message-url-link {
  @apply underline cursor-pointer !important;
  color: #1a73cd !important;
  font-weight: 700 !important;
}

.str-chat__message
  .str-chat__message-inner
  .str-chat__message-bubble
  .str-chat__message-text
  p:not(:first-of-type),
.str-chat__quoted-message-preview
  .str-chat__message-inner
  .str-chat__message-bubble
  .str-chat__message-text
  p:not(:first-of-type) {
  margin: 12px 0 0;
}

.str-chat__channel {
  color: inherit;
}

.str-chat__message-attachment__voice-recording-widget__title,
.str-chat__attachment-preview-file-name,
.str-chat__attachment-list
  .str-chat__message-attachment__voice-recording-widget
  .str-chat__message-attachment__voice-recording-widget__right-section {
  display: none;
}

.str-chat__attachment-list
  .str-chat__message-attachment__voice-recording-widget
  .str-chat__message-attachment__voice-recording-widget__timer {
  color: #1974cd;
}

.str-chat__attachment-list
  .str-chat__message-attachment__voice-recording-widget
  .str-chat__wave-progress-bar__amplitude-bar,
.str-chat__attachment-list
  .str-chat__message-attachment__voice-recording-widget
  .str-chat__wave-progress-bar__progress-indicator {
  background-color: #1974cd;
}

.str-chat__attachment-list
  .str-chat__message-attachment__voice-recording-widget {
  background-color: #fff;
}

.str-chat__attachment-list
  .str-chat__message-attachment-audio-widget--play-button
  svg
  path {
  fill: #fff;
}

.str-chat-unread-message-separator-text {
  color: #000000 !important;
}
